<template>
  <div class="max-vertical-height">
    <breadcrumbs title="Ad Login"
      :crumbs="[
        { name: store.name, route: 'Store', params: { link: store.link } },
        { name: 'Ad Login', route: 'AdLogin', params: { link: store.link } }]">
    </breadcrumbs>
    <div class="main_content">
      <div class="section page-section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7 col-md-12 col-sm-12 col-12 text-center">
              <div class="ad-login-header">
                <h4>LOG IN OR SIGN UP TO START CREATING {{ offersKidsPages ? 'A KIDS PAGE' : 'AN AD' }}</h4>
              </div>
            </div>
            <div class="col-lg-12 text-center">
              <div id="hiw-login-container">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
#hiw-login-container {
  padding-top: 50px;
}
.ad-login-header {
  background-color: var(--primary-color) !important;
  padding: 10px;
  border-radius: 10px;
}
.ad-login-header h4 {
  color: white;
  margin-bottom: 0px;
}
.auth0-lock.auth0-lock {
  z-index: 1000 !important;
}
.auth0-lock-cred-pane-internal-wrapper {
  max-height: 325px !important;
}
.auth0-lock-header {
  display: none !important;
}
.auth0-lock-header-avatar {
  margin-top: -70px !important;
}
.auth0-lock-content {
  background-color: ;
}
.auth0-lock-name {
  display: none !important;
}
.auth0-lock-widget-container {
  margin-top: 25px;
}
</style>

<script>
import Auth0Lock                 from 'auth0-lock'
import md5                       from 'js-md5'
import to                        from '@/utils/to'
import { mapGetters }            from 'vuex'
import Breadcrumbs               from '@/components/layout/header/Breadcrumbs'
import Repository                from '@/repositories/RepositoryFactory'
const DedicationAdUserRepository = Repository.get('dedication_ad_users')
let $ = window.$

export default {
  name: 'AdLogin',
  components: { Breadcrumbs },
  computed: {
    ...mapGetters([
      'store',
      'offersKidsPages'
    ])
  },
  methods: {
    /**
     * Instantiates auth0 lock for dedication ad accounts
     */
    instantiateLock () {
      let options = {
        container: 'hiw-login-container',
        configurationBaseUrl: 'https://cdn.auth0.com',
        allowForgotPassword: true,
        allowSignUp: true,
        usernameStyle: 'email',
        allowedConnections: ['DB-Yearbook-Market-Sales-Users'],
        auth: { responseType: 'token id_token', params: { scope: 'openid email' } },
        theme: { logo: 'https://s3.amazonaws.com/yearbook.market.03/yearbookmarket_icon_logo.png' }
      }
      this.lock = new Auth0Lock(
        'YM9qSc4YobMOH22sk6F0CKimQ13wdGKm',
        'dedication.yearbookmarket.com',
        options
      )

      this.lock.on('authenticated', this.lockOnAuthenticated)
      this.lock.show()
    },
    /**
     * Lock on authenticated method
     * @param authResult - Auth0 result
     */
    lockOnAuthenticated (authResult) {
      this.authResult = authResult
      this.lock.getUserInfo(authResult.accessToken, this.lockGetUserInfo)
    },
    /**
     * Gets user info after successful authentication
     * @param error - Auth0 error
     * @param profile - Auth0 profile
     */
    lockGetUserInfo: async function (error, profile) {
      if (error) console.log(error)

      // Indicate we're working
      $('.auth0-lock-confirmation-content').append('<p>Redirecting to dedication ads...</p>')

      // Parse out user email
      let adUserData = {
        email: profile.email,
        password: md5(profile.email),
        token: this.authResult.accessToken,
        firstName: profile.email,
        lastName: profile.email
      }

      let err, adUser;
      [err, adUser] = await to(DedicationAdUserRepository.get({ email: profile.email }))
      if (!adUser) throw new Error(err)

      let user;
      if (adUser.data.length === 0) { // create ad user
        [err, user] = await to(DedicationAdUserRepository.create(adUserData))
        if (!user) throw new Error(err)
        this.$store.dispatch('setDedicationUser', user.data[0])
        this.$router.push({ name: 'Ads' })
      } else { // update ad user
        [err, user] = await to(DedicationAdUserRepository.update(adUserData, adUser.data[0].id))
        if (!user) throw new Error(err)
        this.$store.dispatch('setDedicationUser', user.data[0])
        this.$router.push({ name: 'Ads' })
      }
    },
    redirectToStore () {
      window.onpopstate = null
      this.$router.push({ name: 'Store', params: { link: this.store.link }})
    }
  },
  mounted () {
    this.instantiateLock()
    window.onpopstate = this.redirectToStore
  }
}
</script>
