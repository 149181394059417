<template>
  <div class="max-vertical-height">
    <breadcrumbs title="Terms and Conditions"
      :crumbs="[
        { name: store.name, route: 'Store', params: { link: store.link } },
        { name: 'Terms and Conditions', route: 'TermsAndConditions' }]">
    </breadcrumbs>
    <div class="main_content">
      <div class="section page-section">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="term_conditions">
                <h6>Copyright</h6>
                <div>
                  <p>
                    All content appearing on this Web site is the property of Yearbook Market &copy; 2024. All rights reserved.<br/><br/>
                    As a user, you are authorized only to view, copy, print, and distribute documents on this Web site so long
                    as (1) the document is used for informational purposes only, and (2) any copy of the document (or portion
                    thereof) includes the following copyright notice: <span>Yearbook Market &copy; 2024. All rights
                    reserved.</span>
                  </p>
                </div>
                <hr>
                <h6>Trademarks</h6>
                <div>
                  <p>
                    All brand, product, service, and process names appearing on this Web site are trademarks of their
                    respective holders. Reference to or use of a product, service, or process does not imply recommendation,
                    approval, affiliation, or sponsorship of that product, service, or process by <span>Yearbook Market</span>.
                    Nothing contained herein shall be construed as conferring by implication, estoppel, or otherwise any
                    license or right under any patent, copyright, trademark, or other intellectual property right of
                    <span>Yearbook Market</span> or any third party, except as expressly granted herein.
                  </p>
                </div>
                <hr>
                <h6>Use of Site</h6>
                <div>
                  <p>
                    This site may contain other proprietary notices and copyright information, the terms of which must be
                    observed and followed. Information on this site may contain technical inaccuracies or typographical errors.
                    Information, including product pricing and availability, may be changed or updated without notice.
                    <span>Yearbook Market</span> and its subsidiaries reserve the right to refuse service, terminate accounts,
                    and/or cancel orders in its discretion, including, without limitation, if <span>Yearbook Market</span>
                    believes that customer conduct violates applicable law or is harmful to the interests of
                    <span>Yearbook Market</span> and its subsidiaries.
                  </p>
                </div>
                <hr>
                <h6>Privacy Policy</h6>
                <div>
                  <p>
                    <span>Yearbook Market</span> use of personal information that you may submit to
                    <span>Yearbook Market</span> through this Web site is used for the sole purpose of the successful delivery of the product or products purchased.
                    This includes student information to be communicated to a school or yearbook adviser for distribution at school or shipping address for when the product ships to a residence.
                  </p>
                </div>
                <hr>
                <h6>Shipping &amp; Delivery</h6>
                <div>
                  <p>
                    At this time, <span>Yearbook Market</span> ships merchandise to locations within the United States
                    and U.S. territories, including Alaska and Hawaii. Additionally,
                    <span>Yearbook Market</span> ships merchandise to Canada but not to other international locations. The
                    risk of loss and title for all merchandise ordered on this Web site pass to you when the merchandise is
                    delivered to the shipping carrier.
                  </p>
                </div>
                <hr>
                <h6>Refund Policy</h6>
                <div>
                  <p>
                    For all presales (orders placed before the school finishes designing and submitting their yearbook to print), Yearbook Market can issue full refunds up until when
                    the school submits their yearbook to print. Once this occurs, all funds are transferred to the school and all refunds need to be coordinated directly with the school.
                    For all print-on-demand orders, Yearbook Market can only issue refunds if the order is still in the "Waiting" status. If it has started to print, we are unable to issue
                    a refund since the school will have already been invoiced for your order by this time. Please contact us if you have any further questions.
                  </p>
                </div>
                <hr>
                <h6>Warranties</h6>
                <div>
                  <p>
                    The Content included in this Web site has been compiled from a variety of sources and is subject to
                    change without notice as are any products, programs, offerings, or technical information described in this
                    Web site. <span>Yearbook Market</span> makes no representation or warranty whatsoever regarding the
                    completeness, quality, or adequacy of the Web site or Content, or the suitability, functionality, or
                    operation of this Web site or its Content. By using this Web site, you assume the risk that the Content
                    on this Web site may be inaccurate, incomplete, offensive, or may not meet your needs and requirements.
                    <span>Yearbook Market</span> SPECIFICALLY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT
                    LIMITATION THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NONINFRINGEMENT WITH
                    RESPECT TO THESE WEB PAGES AND CONTENT. IN NO EVENT WILL <span>Yearbook Market</span> BE LIABLE FOR ANY
                    SPECIAL, INDIRECT, INCIDENTAL, OR CONSEQUENTIAL DAMAGES EVEN IF COMPANY HAS BEEN ADVISED OF THE
                    POSSIBILITY OF SUCH DAMAGES. <br><br> -- OR Depending on State -- <br><br> The information and content on
                    this server is provided "as is" with no warranty of any kind, either express or implied, including but not
                    limited to the implied warranties of merchantability, fitness for a particular purpose, and
                    non-infringement. Any warranty that is provided in connection with any of the products and services
                    described on this Web site is provided by the advertiser or manufacturer only, and not by
                    <span>Yearbook Market</span>. <br><br> The references and descriptions of products or services within the
                    Web site materials are provided "as is" without any warranty of any kind, either express or implied.
                    <span>Yearbook Market</span> is not liable for any damages, including any consequential damages, of any
                    kind that may result to the user from the use of the materials on this Web site or of any of the products
                    or services described hereon. <br><br> The descriptions of, and references to, products, services and
                    companies on this Web site are the sole responsibility of the companies providing the information
                    ("advertisers"), and not <span>Yearbook Market</span>. <br><br> The inclusion of material on this server
                    does not imply any endorsement by <span>Yearbook Market</span>, which makes no warranty of any kind with
                    respect to the subject matter of the server materials advertised. <br><br> A possibility exists that the
                    server materials could include inaccuracies or errors. Additionally, a possibility exists that
                    unauthorized additions, deletions, and alterations could be made by third parties to the server
                    materials. Although <span>Yearbook Market</span> tries to ensure the integrity and the accurateness of
                    the server materials, it makes no guarantees about their correctness or accuracy. Before relying on any
                    representation made in any of the server materials, check with the advertiser of the product or service to
                    ensure that the information you are relying upon is correct.
                  </p>
                </div>
                <hr>
                <h6>Miscellaneous</h6>
                <div>
                  <p>
                    VOID WHERE PROHIBITED:<br> Although the information on this Web site is accessible worldwide, not all
                    products or services discussed in this Web site are available to all persons or in all geographic
                    locations or jurisdictions. <span>Yearbook Market</span> and the advertisers each reserve the right to
                    limit the provision of their products or services to any person, geographic area, or jurisdiction they so
                    desire and to limit the quantities of any products or services that they provide. Any offer for any
                    product or service made in the materials on this Web site is void where prohibited. <br><br>
                    GOVERNING LAW:<br> In the event of litigation both parties agree that the Law of the State of business
                    registration of <span>Yearbook Market</span> shall apply and both parties shall consent to the
                    jurisdiction of said States courts, or in the event of diversity of citizenship, the United States
                    District Court for the (District). Both parties expressly waive a trial by jury. <br><br>
                    MISCELLANEOUS: The Terms and Conditions constitute the entire agreement between you and
                    <span>Yearbook Market</span> with respect to this Web site. The Terms and Conditions supercede all prior
                    or contemporaneous communications and proposals, whether electronic, oral or written between you and
                    <span>Yearbook Market</span> with respect to this Web site. No modification of the Terms and Conditions
                    shall be effective unless it is authorized by <span>Yearbook Market</span>. If any provision of the
                    Terms and Conditions is found to be contrary to law, then such provision(s) shall be constructed in a
                    manner to closely reflect, as much as possible, the intentions of the parties, with the other provisions
                    remaining in full force and effect.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumbs from '@/components/layout/header/Breadcrumbs'

export default {
  name: 'TermsAndConditions',
  components: { Breadcrumbs },
  computed: {
    ...mapGetters([
      'store'
    ])
  }
}
</script>
