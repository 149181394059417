<template>
  <div>
    <template v-if="isLanding || !store.id" >
      <a class="navbar-brand">
        <img id="branded-logo" :src="$route.path === '/amilestonegroup' ? '/assets/images/icons/amilestonegroup-logo.png' :
          $route.path === '/wilcoxrecognition' ? '/assets/images/icons/wilcox.png' : '/assets/images/icons/yearbookmarketicon.png'" alt="logo" />
      </a>
    </template>
    <template v-else>
      <router-link class="navbar-brand" :to="{ name: 'Store', params: { link: store.link } }">
        <img v-if="account.uriImage" id="branded-logo" :src="account.uriImage" alt="logo" />
        <div v-else-if="account" id="branded-logo" class="letter-logo">
          <p>{{ generatePlainTextName() }}</p>
        </div>
      </router-link>
    </template>
  </div>
</template>

<style>
.navbar-brand {
  padding: 3px;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  background: white;
}
.letter-logo {
  padding-top: 20px;
  text-align: center
}
.letter-logo p {
  padding-top: 10px;
  font-weight: bold;
  font-size: 24px;
}
#branded-logo {
  border-radius: 50%;
  height: 85px;
  width: auto;
}
@media only screen and (max-width: 600px) {
  #branded-logo {
    height: 80px;
    width: auto;
  }
}
@media only screen and (max-width: 380px) {
  #branded-logo {
    height: 70px;
    width: auto;
  }
}
</style>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Logo',
  props: {
    isLanding: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'store',
      'account'
    ])
  },
  methods: {
    generatePlainTextName () {
      let namePieces = this.account.name ? this.account.name.replace(/[^\w\s]/gi, '').split(' ') : 'YBM'
      let ptn = ''
      for (let i = 0; i < namePieces.length; i++) {
        ptn += namePieces[i].substring(0, 1).toUpperCase()
      }
      return ptn
    }
  }
}
</script>
