import Client from './Clients/AxiosClient'
import { parseFilters } from './utils/ParseFilters'
const resource = 'online_orders'

export default {
  get (filters) {
    return Client.get(`${resource}?${parseFilters(filters)}`)
      .then(response => response.data)
  },
  getOnlineOrder (id, filters) {
    return Client.get(`${resource}/${id}?${parseFilters(filters)}`)
      .then(response => response.data)
  },
  create (payload) {
    return Client.post(`${resource}`, payload)
      .then(response => response.data)
  },
  update (payload, id) {
    return Client.put(`${resource}/${id}`, payload)
      .then(response => response.data)
  },
  delete (id) {
    return Client.delete(`${resource}/${id}`)
      .then(response => response.data)
  },
  chargeStripeOrder (data) {
    return Client.post(`payments/online_order_stripe`, data)
      .then(response => response.data)
  },
  chargeFreeOrderStripe (data) {
    return Client.post(`payments/online_order_free_stripe`, data)
      .then(response => response.data)
  }
}
