import axios from 'axios'
import store from '@/store'

const baseDomain = `${process.env.VUE_APP_SSY_API_HOST}:${process.env.VUE_APP_SSY_API_PORT}`
const baseUrl = `${baseDomain}` // In case of /api/v1

const CLIENT = axios.create({
  baseURL: baseUrl,
  headers: {
    'x-session-store' : `${store.getters.store.id} | ${store.getters.store.link} | ${store.getters.account.id} | ${store.getters.account.name}`,
  },
  // `transformResponse` allows changes to the response data to be made before
  // it is passed to then/catch
  transformResponse: [function (data) {
    // Do whatever you want to transform the data
    if (data) {
      try {
        const jsonData = JSON.parse(data)
        // Skip version mismatch reload in development env
        if (process.env.VUE_APP_ENV !== 'development' &&
        jsonData.clientsVersion &&
        jsonData.clientsVersion.storefronts !== process.env.VUE_APP_STOREFRONTS_VERSION) {
          console.log('Client version: ' + process.env.VUE_APP_STOREFRONTS_VERSION)
          window.location.reload(true)
        }
        return jsonData
      } catch (err) {
        return data
      }
    } else { // head on resource creation
      let formattedData = {
        status: 204,
        data: []
      }
      return formattedData
    }
  }]
})

// REQUEST INTERCEPTOR, used for auditing
CLIENT.interceptors.request.use(config => {
  config.headers['x-session-store'] = `${store.getters.store.id} | ${store.getters.store.link} | ${store.getters.account.id} | ${store.getters.account.name}`
  return config
}, (error) => {
  return Promise.reject(error)
})

export default CLIENT
