import Vue from 'vue'
import App from './App.vue'
import Vuelidate from 'vuelidate'
import Select2 from 'v-select2-component'
import Vuesax from 'vuesax'
import VueMask from 'v-mask'
import VueFilterDateFormat from 'vue-filter-date-format'
import Rollbar from 'vue-rollbar'
import VueGtag from 'vue-gtag'
import 'vuesax/dist/vuesax.css'
// import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.use(VueMask)
Vue.use(Vuelidate)
Vue.use(Vuesax)
Vue.use(VueFilterDateFormat)
Vue.component('Select2', Select2)

// Vue rollbar
Vue.use(Rollbar, {
  accessToken                : '67cd24946eb94a099f04f236ff3f8bae',
  captureUncaught            : true,
  captureUnhandledRejections : true,
  enabled                    : true,
  source_map_enabled         : false,
  environment                : process.env.VUE_APP_ENV,
  payload: {
    client: {
      javascript: {
        code_version: '1.0'
      }
    }
  }
})
Vue.config.errorHandler = err => {
  Vue.rollbar.error(err)
}

// Filters
import { setCommonFilters } from '@/filters/common'
setCommonFilters(Vue)

// Google Analytics
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_ID }
}, router)

// router on error, reloads after new deployment
router.onError(error => {
  if (/loading chunk ([^\s]+) failed/i.test(error.message)) {
    window.location.reload()
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
