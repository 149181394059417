<template>
  <div id="app">
    <div class="preloader" id="main-preloader">
      <div class="lds-ellipsis" style="width: 275px;">
        <span></span>
        <span></span>
        <span></span>
        <p style="margin-top: 10px;">{{ dedicationAdRedirect ? 'Redirecting back to the store...' : '' }}</p>
      </div>
    </div>
    <HeaderMain :bAds="offersAdBuilding" :showLinks="$route.name !== 'Stores'"></HeaderMain>
    <transition name="slide-right">
      <router-view/>
    </transition>
    <FooterMain></FooterMain>
  </div>
</template>

<script>
import { mapGetters }   from 'vuex'
import to               from '@/utils/to'
import HeaderMain       from '@/components/layout/header/HeaderMain'
import FooterMain       from '@/components/layout/footer/FooterMain'
import Repository       from '@/repositories/RepositoryFactory'
const AccountRepository = Repository.get('accounts')
const StoreRepository   = Repository.get('stores')
const ProductRepository = Repository.get('products')

let $ = window.$

export default {
  name: 'App',
  components: { HeaderMain, FooterMain },
  computed: {
    ...mapGetters([
      'offersAdBuilding',
      'dedicationAdRedirect'
    ])
  },
  methods: {
    /**
     * Gets store
     * @param link - Store link
     */
    getStore: async function (link) {
      let err, store;
      [err, store] = await to(StoreRepository.get({ link: link }))
      if (!store || store.data.length === 0) {
        this.$router.push({ name: 'Stores' })
        console.log(err)
        throw new Error('Store with link does not exist: ' + link)
      }
      let s = store.data[0]
      this.$store.dispatch('setStore', s)
      await this.getAccount(s.accountId)
      await this.getProducts(s.id)
      // Set propery root primary color
      if (s.primaryColor) {
        $('head').append('<style type="text/css"></style>')
        var newStyleElement = $('head').children(':last')
        newStyleElement.html(':root{ --primary-color:' + s.primaryColor + ' !important; }')
      }
      setTimeout(function () {
        $('#main-preloader').delay(700).fadeOut(700).addClass('loaded')
        window.Beacon('prefill', { subject: `Support Request for ${s.name} - ${s.accountId}` })
      }, 800)
    },
    /**
     * Gets account and parent associated with the store
     * @param id - Account ID
     */
    getAccount: async function (id) {
      let err, account;
      [err, account] = await to(AccountRepository.getAccount(id, { include: ['parent'] }))
      if (!account) {
        this.$router.push({ name: 'Stores' })
        throw new Error(err)
      }
      if (account.data.length === 0) this.$router.push({ name: 'Stores' })
      let a = account.data[0]
      this.$store.dispatch('setAccount', a)
      await this.getParentStripeConnectAccount(a.parentId)
    },
    /**
     * Gets parent's stripe connect account if it exists
     * @param id - Parent ID
     */
    getParentStripeConnectAccount: async function (id) {
      let err, sa;
      [err, sa] = await to(AccountRepository.getStripeConnectAccount({ accountId: id, status: 1 }))
      if (err || !sa || sa.data.length === 0) {
        this.$store.dispatch('setStripConnectAccount', null)
      } else {
        let stripeConnectAccount = sa.data[0]
        this.$store.dispatch('setStripConnectAccount', stripeConnectAccount)
      }
    },
    /**
     * Gets products and prices associated with the store
     * @param storeId - Store ID
     */
    getProducts: async function (storeId) {
      let err, products;
      [err, products] = await to(ProductRepository.get({ 
                                                        storeId: storeId,
                                                        include: ['prices'],
                                                        order: 'year ASC, name ASC'
                                                      }))
      if (!products)  {
        this.$router.push({ name: 'Stores' })
        throw new Error(err)
      }
      this.$store.dispatch('setProducts', products.data)
    }
  },
  /**
   * On mounted, load store, account and products
   */
  mounted () {
    if (this.$route.name !== 'Stores') {
      if (!this.$route.params.link) this.$router.push({ name: 'Stores' })
      this.getStore(this.$route.params.link)
    }
  }
}
</script>
