<template>
  <li class='dropdown-li'>
    <a @click="$store.dispatch('removeItemFromCart', idx)" class="item_remove">
      <i class="ion-close"></i>
    </a>
    <div class="product-name-size">
      <img v-if="item.ad" :src="item.ad.uriPreview" alt="cart_thumb1">
      <img v-else :src="productImageUri(item.product)" alt="cart_thumb1">
      <span>{{ item.product.name }}</span>
    </div>
    <span class="cart_quantity">
      {{ item.quantity }} x
      <span class="cart_amount">
        ${{ item.itemPrice.toFixed(2) }} (${{ (item.quantity * item.itemPrice).toFixed(2) }})
      </span>
    </span>
    <template v-if="item.donated">
      <span>Donated</span>
    </template>
    <template v-else>
      <span> {{ item.student.firstName }} {{ item.student.lastName }}</span><br>
      <span> {{ item.student.teacher }}, {{ item.student.grade }}</span>
    </template>
  </li>
</template>

<style>
.dropdown-li {
  font-size: 10px;
}
.product-name-size {
  font-size: 12px;
  color: #000 !important;
  font-weight: bold;
}
.cart_quantity {
  font-size: 11px;
  display: block;
}
</style>

<script>
import { storeCommonMixin } from '@/mixins/storeCommon'

export default {
  name: 'CartDropdownItem',
  mixins: [storeCommonMixin],
  props: {
    item: {
      type: Object,
      required: true
    },
    idx: {
      type: Number,
      required: true
    }
  }
}
</script>
