export { buildMessage }

function buildMessage (resource, action, data, postActions) {
  let post = !postActions ? [] : postActions.map(function (pa) {
    return {
      key: `${pa.resource}.${pa.action}`,
      resource: pa.resource,
      action: pa.action,
      data: pa.data,
      fields: pa.fields
    }
  })
  return {
    routing_key: `${resource}.${action}`,
    message: {
      resource: resource,
      action: action,
      data: data,
      post: post
    }
  }
}
