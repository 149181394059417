<template>
  <footer class="footer_light" style="background-color:#f7f8fb;">
      <div class="bottom_footer border-top-tran">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <p class="mb-md-0 text-center text-md-left" style="margin-top: 7px;">&copy; {{ new Date().getFullYear() }} Powered by Yearbook Market</p>
            </div>
            <div class="col-md-6">
              <ul class="footer_payment footer_payment_padding text-center text-md-right">
                <li><img src="/assets/images/visa.png" alt="visa"></li>
                <li><img src="/assets/images/discover.png" alt="discover"></li>
                <li><img src="/assets/images/master_card.png" alt="master_card"></li>
                <li><img src="/assets/images/amarican_express.png" alt="amarican_express"></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
</template>

<style>
.bottom_footer {
  padding: 5px 0;
}
.footer_payment_padding li {
  padding: 5px !important;
}
</style>

<script>
export default {
  name: 'FooterMain'
}
</script>
