export { parseFilters }

/**
 * Parses query filters
 * @param filters - Filters object
 * @return {String} - Query string
 */
function parseFilters (filters) {
  if (!filters) {
    return ''
  }
  let queryString = ''

  for (let prop in filters) {
    if (prop === 'include') {
      queryString += '&include=' + filters[prop].join(',')
    } else if (Array.isArray(filters[prop])) {
      for (let i = 0; i < filters[prop].length; i++) {
        queryString += `&${prop}[]=` + filters[prop][i]
      }
    } else {
      if (filters[prop] !== '') {
        queryString += `&${prop}=${filters[prop]}`
      }
    }
  }

  return queryString
}
