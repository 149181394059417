<template>
  <div class="max-vertical-height">
    <breadcrumbs :title="ad.title"
      :crumbs="[
        { name: store.name, route: 'Store', params: { link: store.link } },
        { name: ad.productName, route: 'Ads', params: { link: store.link }},
        { name: ad.title, route: 'Ad', params: { link: store.link, id: ad.id }}]">
    </breadcrumbs>
    <div class="main_content">
      <div class="section product-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-7 col-sm-12 col-12">
              <div class="row">
              <div class="col-lg-5 col-md-4 mb-4 mb-md-0">
                <div class="product-image">
                  <div class="product_img_box">
                    <img id="product_img" :src='ad.uriPreview' alt="product_img1" />
                  </div>
                </div>
              </div>
              <div class="col-lg-7 col-md-8">
                <div class="pr_detail">
                  <div class="product_description">
                    <h4 class="product_title">{{ ad.title }}</h4>
                    <div>
                      <span class="price">${{ Number(ad.productPrice).toFixed(2) }}</span>
                    </div>
                    <div class="pr_desc">
                      <p>{{ ad.productDescription }}</p>
                    </div>
                    <div class="product_sort_info">
                      <ul v-if="product.callouts && product.callouts.length > 0">
                        <li v-for="callout in product.callouts" :key="callout">
                          <i class="linearicons-shield-check"></i> {{ callout }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div class="col-lg-6 col-md-5 col-sm-12 col-12">
              <div class="row">
                <div class="col-12">
                  <div class="tab-style3">
                    <ul class="nav nav-tabs" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" id="add-info-tab" data-toggle="tab" href="#AddInfo" role="tab" aria-controls="AddInfo" aria-selected="true">Add Information</a>
                      </li>
                    </ul>
                    <div class="tab-content shop_info_tab">
                      <div class="tab-pane fade show active" id="AddInfo" role="tabpanel" aria-labelledby="add-info-tab">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="field_form">
                              <fieldset>
                                <div class="row">
                                  <div class="form-group col-md-12">
                                    <input required placeholder="Student first name *" class="form-control" v-model="student.firstName" name="firstName" type="text">
                                    <small class="validation-error-text" v-if="$v.$anyError && !$v.student.firstName.required">First name is required</small>
                                    <small class="validation-error-text" v-if="$v.$anyError && !$v.student.firstName.maxLength">First name cannot be more than 100 characters</small>
                                  </div>
                                  <div class="form-group col-md-12">
                                    <input required placeholder="Student last name *" class="form-control" v-model="student.lastName" name="lastName" type="lastName">
                                    <small class="validation-error-text" v-if="$v.$anyError && !$v.student.lastName.required">Last name is required</small>
                                    <small class="validation-error-text" v-if="$v.$anyError && !$v.student.lastName.maxLength">Last name cannot be more than 100 characters</small>
                                  </div>
                                  <div class="form-group col-md-12">
                                    <input required placeholder="Teacher *" class="form-control" v-model="student.teacher" name="teacher">
                                    <small class="validation-error-text" v-if="$v.$anyError && !$v.student.teacher.maxLength">Teacher name cannot be more than 100 characters</small>
                                  </div>
                                  <div class="form-group col-md-12">
                                    <input placeholder="Grade *" class="form-control" v-model="student.grade" name="grade">
                                    <small class="validation-error-text" v-if="$v.$anyError && !$v.student.grade.maxLength">Grade cannot be more than 100 characters</small>
                                  </div>
                                </div>
                              </fieldset>
                            </div>
                          </div>
                          <div class="col-lg-12 pt-2 pt-lg-0 mt-4 mt-lg-0">
                            <div class="cart_extra">
                              <h4 class="mr-3 mt-2">${{ (Number(ad.productPrice) * quantity).toFixed(2) }}</h4>
                              <div class="cart-product-quantity">
                                <div class="quantity">
                                  <input type="button" value="-" class="minus" @click="quantity = quantity === 1 ? quantity : quantity - 1">
                                  <input type="text" disabled name="quantity" v-model="quantity" title="Qty" class="qty" size="4">
                                  <input type="button" value="+" class="plus" @click="quantity = quantity + 1">
                                </div>
                              </div>
                              <div class="cart_btn">
                                <button class="btn btn-fill-out btn-addtocart" type="button" @click="addItemToCart">
                                  <i class="icon-basket-loaded"></i> Add to cart
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.product-section {
  padding-top: 40px;
}
#product_img {
  max-height: 200px;
}
.product_img_box {
  text-align: center;
  padding: 10px;
}
.shop_info_tab {
  margin-top: 20px !important;
}
.vs-notifications {
  color: white !important;
}
.con-text-noti h3 {
  margin-bottom: 0px !important;;
  color: white !important;

}
.con-text-noti p {
  margin-bottom: 10px !important;
  color: white !important;

}
</style>

<script>
import to                     from '@/utils/to'
import { mapGetters }         from 'vuex'
import { storeCommonMixin }   from '@/mixins/storeCommon'
import Breadcrumbs            from '@/components/layout/header/Breadcrumbs'
import Repository             from '@/repositories/RepositoryFactory'
const ProductRepository       = Repository.get('products')
const DedicationAdRepository  = Repository.get('dedication_ads')
const { required, maxLength } = require('vuelidate/lib/validators')

let $ = window.$

export default {
  name: 'Ad',
  components: { Breadcrumbs },
  mixins: [storeCommonMixin],
  data() {
    return {
      ad: { product: {} },
      product: { prices: [] },
      student: {
        firstName: '',
        lastName: '',
        teacher: '',
        grade: ''
      },
      quantity: 1
    }
  },
  validations: {
    student: {
      firstName: {
        required,
        maxLength: maxLength(100)
      },
      lastName: {
        required,
        maxLength: maxLength(100)
      },
      teacher: {
        required,
        maxLength: maxLength(100)
      },
      grade: {
        required,
        maxLength: maxLength(100)
      }
    }
  },
  computed: {
    ...mapGetters([
      'account',
      'cart',
      'dedicationUser',
      'store'
    ]),
    expired () {
      let price = this.getProductPrice(this.product, this.account.timezone)
      return price === -1 || Number(this.ad.productPrice) < 0
    }
  },
  methods: {
    /**
     * Gets dedication ad
     * @param id - Dedication ad product
     * @param userId - Dedication ad user ID
     */
    getDedicationAd: async function (id, userId) {
      let err, ad;
      [err, ad] = await to(DedicationAdRepository.getDedicationAd(id, { dedicationAdUser: userId }))
      if (!ad) {
        window.location.href = `/${this.store.link}`
        console.log(err)
      } else {
        this.ad = ad.data[0]
        await this.getProduct(this.ad.productId)
      }
    },
    getProduct: async function (id) {
      let err, product;
      [err, product] = await to(ProductRepository.getProduct(id, { include: ['prices'] }))
      if (!product) throw new Error(err)
      this.product = product.data[0]
      this.hideLoader($)
    },
    /**
     * Adds item to cart
     */
    addItemToCart () {
      this.$v.$touch()
      if (this.$v.$invalid) {
          this.$vs.notify({
            color:    'danger',
            title:    'Warning',
            text:     'Missing or required fields require attention',
            position: 'top-right'
          })
      } else {
        if (this.checkCrossStoreOrder(this.store.id, this.cart, this.product.storeId)) return
        if (this.expired) {
          this.$vs.notify({
            color:    'danger',
            title:    'Warning',
            text:     'This product has expired and cannot be ordered at this time.',
            position: 'top-right'
          })
          return
        }
        let item = {
          ad: this.ad,
          donated: false,
          itemPrice: Number(this.ad.productPrice),
          message: this.message,
          personalizationCost: this.personalizationCost,
          personalized: false,
          product: this.product,
          productPrice: Number(this.ad.productPrice),
          project: null,
          quantity: this.quantity,
          storeId: this.store.id,
          storeLink: this.store.link,
          student: this.student,
          typePersonalization: this.product.typePersonalization
        }
        this.$store.dispatch('addItemToCart', item)
        this.$vs.notify({
          color:    'success',
          title:    'Added to Cart',
          text:     'The product has been added to your cart',
          position: 'top-right'
        })
        this.$router.push({ name: 'Store', params: { link: this.store.link }})
      }
    }
  },
  created: async function () {
    this.showLoader($)
    await this.getDedicationAd(this.$route.params.id, this.dedicationUser.id)
  }
}
</script>
