<template>
  <div class="max-vertical-height">
    <breadcrumbs title="Frequently Asked Questions"
      :crumbs="[
        { name: store.name, route: 'Store', params: { link: store.link } },
        { name: 'Frequently Asked Questions', route: 'FAQ', params: { link: store.link } }]">
    </breadcrumbs>
    <div class="main_content">
      <div class="section page-section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="heading_s1 mb-3 mb-md-5">
                <h3>General questions</h3>
              </div>
              <div id="accordion" class="accordion accordion_style1">
                <div class="card">
                  <div class="card-header" id="headingOne">
                    <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Did I already place an order?</a> </h6>
                  </div>
                  <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                      <p>Simply click our Beacon help button in the bottom right hand corner of the page, and provide your email address and student information so we can check our order records. We will get back to you within 24hrs.</p>
                      <img src="@/assets/beaconhelpbutton.jpg" style="max-height: 650px;" />
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingTwo">
                    <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">When will I receive my yearbook?</a> </h6>
                  </div>
                  <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                    <div class="card-body">
                      <p>Yearbooks are distributed to students at the discretion of the school. Many schools have the yearbooks shipped directly to the school in the Spring.
                        Some schools will offer a ship-to-home option for their yearbooks. You will see this option on the yearbook product page.
                        <img src="@/assets/shiptohomeoption.png" />
                        These orders are also released when the school finishes designing their yearbook and submitting it to print.
                        Yearbook ship-to-home delivery utilizes a combination of UPS and USPS handling to get your yearbook delivered at the lowest possible cost.
                        Typical transit times are 7-10 business days. Depending on where you live and the efficiency of your local post office, transit time can be as long as 3 weeks.</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingThree">
                    <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">I misspelled the student / staff name? What can I do?</a> </h6>
                  </div>
                  <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                    <div class="card-body">
                      <p>Simply click our Beacon help button in the bottom right hand corner of the page, and provide your email address, order confirmation number, and updated information and we will take care of the rest. We will confirm the update within 24hrs.</p>
                      <img src="@/assets/beaconhelpbutton.jpg" />
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingFour">
                    <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">Why does my order receipt show shipping as None?</a> </h6>
                  </div>
                  <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                    <div class="card-body">
                      <p>Products like dedication ads and free text are not shipped, so we classify those as having a shipping type of None.
                        If you only purchase these items and checkout, you will see shipping as None on your receipt.</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingFive">
                    <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Can the yearbook be shipped to me?</a> </h6>
                  </div>
                  <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                    <div class="card-body">
                      <p>Only the school can set up their product to ship-to-home. Please contact your school / yearbook adviser for more information.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mt-4 mt-md-0">
              <div class="heading_s1 mb-3 mb-md-5">
                <h3>Technical questions</h3>
              </div>
              <div id="accordion2" class="accordion accordion_style1">
                <div class="card">
                  <div class="card-header" id="headingSix">
                    <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapseSix" aria-expanded="true" aria-controls="collapseSix">How do I remove an item from my cart?</a> </h6>
                  </div>
                  <div id="collapseSix" class="collapse show" aria-labelledby="headingSix" data-parent="#accordion2">
                    <div class="card-body">
                      <p>There are two ways to remove an item from your cart. Scroll over the cart icon in the upper right hand corner and select the "X" next to the product name.
                        <img src="@/assets/removeitemfromcart1.png" class="faq-image" />
                        You can also navigate to the cart page and adjust the quantity or remove the item as shown in the screenshot below.
                        <img src="@/assets/removeitemfromcart2.png" class="faq-image" />
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingSeven">
                    <h6 class="mb-0"> <a class="collapsed" data-toggle="collapse" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">Why was my card declined?</a> </h6>
                  </div>
                  <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion2">
                    <div class="card-body">
                      <p>We use a secure, PCI compliant payment processor that validates your card number, expiration date, CVV and billing zipcode.
                        If any of these are incorrect, or if your bank blocks the transaction, you will receive a notice that your card was declined.
                        Yearbook Market (the shopping cart you are using now) has no control over accepting or declining your payment.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.faq-image {
  display: block;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>

<script>
import { mapGetters } from 'vuex'
import Breadcrumbs from '@/components/layout/header/Breadcrumbs'

export default {
  name: 'FAQ',
  components: { Breadcrumbs },
  computed: {
    ...mapGetters([
      'store'
    ])
  }
}
</script>
