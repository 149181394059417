export { setCommonFilters }

const moment = require('moment-timezone')

function setCommonFilters (Vue) {
  /**
   * Date vue filter
   * Converts PostgreSQL date string to datepicker date string
   * @param value - Value to format
   * @param userTimezone - Timezone
   */
  Vue.filter('filterDate', function (value, timezone) {
    let valueCheck = new Date(value)
    if (!valueCheck) {
      return 'None'
    }

    if (valueCheck.getFullYear() < 1990) {
      return 'None'
    }

    // Timezone conversion
    let utz = timezone
    let tiz = 'America/Chicago'
    if (utz === 'EST') {
      tiz = 'America/New_York'
    } else if (utz === 'CST') {
      tiz = 'America/Chicago'
    } else if (utz === 'MST') {
      tiz = 'America/Denver'
    } else if (utz === 'PST') {
      tiz = 'America/Los_Angeles'
    }
    let utcDate = moment.tz(value, 'Europe/London')
    return (utcDate.tz(tiz)).format('MM/DD/YYYY')
  })

  /**
   * DateTime vue filter
   * Converts PostgreSQL date string to date time representation
   * @param value - Value to format
   * @param userTimezone - timezone
   */
  Vue.filter('filterDateTime', function (value, timezone) {
    let valueCheck = new Date(value)
    if (!valueCheck) {
      return 'None'
    }

    if (valueCheck.getFullYear() < 1990) {
      return 'None'
    }

    // Timezone conversion
    let utz = timezone
    let tiz = ''
    if (utz === 'EST') {
      tiz = 'America/New_York'
    } else if (utz === 'CST') {
      tiz = 'America/Chicago'
    } else if (utz === 'MST') {
      tiz = 'America/Denver'
    } else if (utz === 'PST') {
      tiz = 'America/Los_Angeles'
    }
    let utcDate = moment.tz(value, 'Europe/London')
    return (utcDate.tz(tiz)).format('MM/DD/YYYY h:mma')
  })

  /**
   * Personalization type filter
   * Converts personalization type to string
   * @param value - Value to format
   */
  Vue.filter('personalizationTypeFilter', function (value) {
    let v = ''
    value = Number(value)

    if (value === 0) {
      v = ''
    } else if (value === 1) {
      v = 'Name Personalization'
    } else if (value === 2) {
      v = 'Name and Photo Personalization'
    } else if (value === 3) {
      v = ''
    } else if (value === 4) {
      v = 'Name and Icons Personalization'
    }

    return v
  })
}
