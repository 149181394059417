import Client from './Clients/AxiosClient'
import { parseFilters } from './utils/ParseFilters'
const resource = 'projects'

export default {
  get(filters) {
    return Client.get(`${resource}?${parseFilters(filters)}`)
      .then(response => response.data)
  },
  getProject (id, filters) {
    return Client.get(`${resource}/${id}?${parseFilters(filters)}`)
      .then(response => response.data)
  }
}
