<template>
  <div class="breadcrumb_section bg_gray page-title-mini">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="page-title">
            <h1>{{ title }}</h1>
          </div>
        </div>
        <div class="col-md-6">
          <ol class="breadcrumb justify-content-md-end">
            <li :class="['breadcrumb-item', idx === crumbs.length - 1 ? 'active' : '']" v-for="(crumb, idx) in crumbs" :key="crumb.name">
              <template v-if="idx === crumbs.length - 1">
                {{ crumb.name }}
              </template>
              <template v-else>
                <router-link :to="{ name: crumb.route, params: crumb.params }">
                  {{ crumb.name }}
                </router-link>
              </template>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.page-title h1 {
  font-size: 28px !important;
}
.breadcrumb li {
  font-size: 14px !important;
}
.breadcrumb_section {
  padding-top: 105px !important;
  padding-bottom: 20px !important;
}
@media screen and (max-width: 600px) {
  .breadcrumb li {
    font-size: 3vw !important;
  }
  .page-title h1 {
    font-size: 2.25vh !important;
  }
}
</style>

<script>
export default {
  props: {
    title: {
      required: true
    },
    crumbs: {
      type: Array,
      required: true
    }
  }
}
</script>
