import Client from './Clients/AxiosClient'
import { parseFilters } from './utils/ParseFilters'
const resource = 'discounts'

export default {
  get(filters) {
    return Client.get(`${resource}?${parseFilters(filters)}`)
      .then(response => response.data)
  },
  getDiscount (id, filters) {
    return Client.get(`${resource}/${id}?${parseFilters(filters)}`)
      .then(response => response.data)
  },
  create (payload) {
    return Client.post(`${resource}`, payload)
      .then(response => response.data)
  },
  update (payload, id) {
    return Client.put(`${resource}/${id}`, payload)
      .then(response => response.data)
  },
  delete (id) {
    return Client.delete(`${resource}/${id}`)
      .then(response => response.data)
  }
}
