const moment = require('moment-timezone')
/**
 * Returns the timezone name based on abbrev
 * @param timezone - String with timezone abbreviation. For example, 'PST'
 */
function getTimezoneNameFromAbbrv (abbrv) {
  const timezoneMap = {
    'EST': 'America/New_York',
    'CST': 'America/Chicago',
    'MST': 'America/Denver',
    'PST': 'America/Los_Angeles',
    'HST': 'Pacific/Honolulu',
    'JST': 'Asia/Amman'
    };
  return timezoneMap[abbrv] || ''
}

export var storeCommonMixin = {
  methods: {
    /**
     * Display product image
     * @param product - Product item
     */
    productImageUri (product) {
      if (product.uriImage) {
        return product.uriImage
      } else {
        if (product.typeProduct === 1) {
          return '/assets/images/icons/YB-FlatA_300.png'
        } else if (product.typeProduct === 2) {
          if (product.typeDedicationAd === 1) return '/assets/images/icons/Ads-Flat_FullPg_300.png'
          else if (product.typeDedicationAd === 2) return '/assets/images/icons/Ads-Flat_HalfPg_300.png'
          else if (product.typeDedicationAd === 3) return '/assets/images/icons/Ads-Flat_QuartPg_300.png'
          else if (product.typeDedicationAd === 4) return '/assets/images/icons/Ads-Flat_8thPg_300.png'
        } else if (product.typeProduct === 3) {
          return '/assets/images/icons/text-only-message.jpg'
        } else if (product.typeProduct === 4) {
          return '/assets/images/icons/FC-Flat_ComboA_300.png'
        } else if (product.typeProduct === 6) {
          return '/assets/images/add-photo-icon.png'
        }
      }
    },
    /**
     * Calculates a products price
     * @param product - Product item
     */
    getProductPrice (product, timezone) {
      let price = -1
      let foundPrice = false
      const timezoneName = getTimezoneNameFromAbbrv(timezone) || ''
      const today =  moment().tz(timezoneName)

      if (product && product.prices) {
        product.prices.forEach(priceObj => {
          const dateStart = moment.tz(priceObj.dateStart, timezoneName)
          const dateEnd = priceObj.dateEnd ? moment.tz(priceObj.dateEnd, timezoneName) : null

          // Checks today against start and end dates
          if (today.isSameOrAfter(dateStart, 'day') && (dateEnd === null || today.isSameOrBefore(dateEnd, 'day')) && !foundPrice ){
            price = Number(priceObj.price)
            foundPrice = true
          }
        })
      }
      return price
    },
    /**
     * Formats product start and end dates
     * @param date - Date to format
     */
    formatProductDates (date) {
      let dateTime = date.split('T')
      let dt = dateTime[0]
      let dtPieces = dt.split('-')
      return (new Date(dtPieces[1] + '/' + dtPieces[2] + '/' + dtPieces[0])).getTime()
    },
    /**
     * Shows loader
     * @param $ - Reference to window jquery
     */
    showLoader ($) {
      $('#main-preloader').removeClass('loaded')
      $('#main-preloader').css('display', 'block')
    },
    /**
     * Hides loader
     * @param $ - Reference to window jquery
     * @param - Fade time in ms
     * @param
     */
    hideLoader ($, fade, timeout) {
      fade = fade || 700
      timeout = timeout || 800
      setTimeout(function () {
        $('#main-preloader').fadeOut(fade).addClass('loaded')
      }, timeout)
    },
    /**
     * Checks cross store ordering
     * @param storeId - Store from which a product will be purchased
     * @param cart - Cart items
     * @param productStoreId - Store ID of the product
     * @return true if cross store order attempted, false otherwise
     */
    checkCrossStoreOrder (storeId, cart, productStoreId=null) {
      if (productStoreId && productStoreId !== storeId) {
        this.$vs.notify({
          color:    'danger',
          title:    'Wait!',
          text:     'You cannot checkout with products from different schools in your cart.',
          position: 'top-right'
        })
        return true
      }
      for (let i = 0; i < cart.length; i++) {
        if (cart[i].storeId !== storeId) {
          this.$vs.notify({
            color:    'danger',
            title:    'Wait!',
            text:     'You cannot checkout with products from different schools in your cart.',
            position: 'top-right'
          })
          return true
        }
      }
      return false
    },
    /**
     * Checks cross shipping ordering
     * @param typeShipping - Type shipping of item being ordered
     * @param store - Vuex store
     * @return true if cross shipping order attempted, false otherwise
     */
    checkCrossShippingOrder (typeShipping, cart) {
      let shippingTypes = []
      for (let i = 0; i < cart.length; i++) {
        shippingTypes.push(cart[i].product.typeShipping)
      }
      // Are we trying to add summer single book to cart with non-type or does the cart always contain summer single book
      if (typeShipping === 2 && (shippingTypes.includes(0) || shippingTypes.includes(1) || shippingTypes.includes(3)) ||
        typeShipping !== 2 && shippingTypes.includes(2)) {
        this.$vs.notify({
          time:     6000,
          color:    'danger',
          title:    'Wait!',
          text:     'Two or more products ship differently. Please purchase previous year yearbooks separately from current year products.',
          position: 'top-right'
        })
        return true
      }
      return false
    },
    /**
     * Checks if different summer single book years are selected
     * @param typeShipping - Type shipping of item being ordered
     * @param year - Year of product to add
     * @param cart - Items in cart
     * @return true if two different years in cart, false otherwise
     */
    checkSummerSingleSameYear (typeShipping, year, cart) {
      // Are we trying to add summer single book to cart with non-type or does the cart always contain summer single book
      if (typeShipping === 2) {
        for (let i = 0; i < cart.length; i++) {
          let item = cart[i]
          if (item.product.typeShipping === 2 && item.product.year !== year) {
            this.$vs.notify({
              time:     7000,
              color:    'danger',
              title:    'Wait!',
              text:     'Please purchase previous year yearbooks separately since each will printed on demand and shipped separately. We apologize for the inconvenience and appreciate your patience!',
              position: 'top-right'
            })
            return true
          }
        }
      }
      return false
    },
    isDateInThePast (date, timezone) {
      let today = this.$options.filters.filterDate(new Date(), timezone)
      today = (new Date(today)).getTime()
      let dateTime = (new Date(date)).getTime()
      return today > dateTime
    }
  }
}
