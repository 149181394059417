var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"max-vertical-height",class:['/amilestonegroup', '/wilcoxrecognition'].includes(_vm.$route.path) ? 'amg-background-image' : '',style:({
  'background-image': _vm.$route.path === '/amilestonegroup' || _vm.$route.path === '/wilcoxrecognition' ? 'url(\'/assets/images/high-school-kids-walking.png\')' : 'url(\'/assets/images/StoreBg_1920x1000.jpg\')',
  'background-repeat': 'none',
  'background-size': 'cover' })},[_c('div',{staticClass:"main_content"},[_c('div',{staticClass:"section page-section simple-suggest-position"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('vue-simple-suggest',{ref:"suggestComponent",attrs:{"pattern":"\\w+","list":_vm.getStores,"max-suggestions":15,"min-length":0,"debounce":400,"filter-by-query":false,"prevent-submit":true,"controls":{
                selectionUp: [38, 33],
                selectionDown: [40, 34],
                select: [13, 36],
                hideList: [27, 35]
              },"mode":_vm.mode,"nullable-select":true,"placeholder":"Start typing your school name here","value-attribute":"id","display-attribute":"name"},on:{"select":_vm.onSuggestSelect},scopedSlots:_vm._u([{key:"misc-item-above",fn:function({ suggestions }){return [(suggestions.length > 0)?[_c('div',{staticClass:"misc-item"},[_c('span',[_vm._v(_vm._s(suggestions.length)+" suggestion(s) are shown...")])]),_c('hr',{staticStyle:{"margin-top":"2px !important","margin-bottom":"2px !important"}})]:(!_vm.loading)?_c('div',{staticClass:"misc-item"},[_c('span',[_vm._v("No results")])]):_vm._e()]}},{key:"suggestion-item",fn:function(scope){return _c('div',{attrs:{"title":scope.suggestion.description}},[_c('div',{staticClass:"text"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.boldenSuggestion(scope))}})])])}}]),model:{value:(_vm.storeSearch),callback:function ($$v) {_vm.storeSearch=$$v},expression:"storeSearch"}},[_c('div',{staticClass:"g"},[_c('input',{staticStyle:{"text-align":"center"},attrs:{"type":"text","placeholder":"Start typing your school name here"}})]),(_vm.loading)?_c('div',{staticClass:"misc-item",attrs:{"slot":"misc-item-below"},slot:"misc-item-below"},[_c('span',[_vm._v("Loading...")])]):_vm._e()])],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }