import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const moment = require('moment-timezone')

/**
 * Calculates a products price and deadline
 * @param product - Product item
 * @param timezone - Account timezone
 */
function getProductPriceAndDeadline (product, timezone) {
  let price = -1
  let deadline = 'None'
  let lastEndDate = -1
  let foundPrice = false
  const timezoneName = getTimezoneNameFromAbbrv(timezone) || ''
  const today =  moment().tz(timezoneName)

  if (!product || !product.prices) {
    return { price, deadline, lastEndDate};
}

  product.prices.forEach(priceObj => {
    const dateStart = moment.tz(priceObj.dateStart, timezoneName)
    const dateEnd = priceObj.dateEnd ? moment.tz(priceObj.dateEnd, timezoneName) : null

    if (dateEnd && dateEnd.isAfter(lastEndDate)){
      lastEndDate = dateEnd
    }

    if (today.isSameOrAfter(dateStart, 'day') && (dateEnd === null || today.isSameOrBefore(dateEnd, 'day')) && !foundPrice ){
      price = Number(priceObj.price)
      deadline = dateEnd
      foundPrice = true
    }
  })

  return { price, deadline, lastEndDate}
}

/**
 * Returns the timezone name based on abbrev
 * @param timezone - String with timezone abbreviation. For example, 'PST'
 */
function getTimezoneNameFromAbbrv (abbrv) {
  const timezoneMap = {
    'EST': 'America/New_York',
    'CST': 'America/Chicago',
    'MST': 'America/Denver',
    'PST': 'America/Los_Angeles',
    'HST': 'Pacific/Honolulu',
    'JST': 'Asia/Amman'
    };
  return timezoneMap[abbrv] || '';
}

/**
 * Filters a date by timezone
 * @param value - Date value
 * @param timezone - Timezone
 */
function filterDate (value, timezone) {
  let valueCheck = new Date(value)
  if (!valueCheck) {
    return 'None provided'
  }

  if (valueCheck.getFullYear() < 1990) {
    return 'None provided'
  }

  // Timezone conversion
  let utz = timezone
  let tiz = ''
  tiz = getTimezoneNameFromAbbrv(utz)
  let utcDate = moment.tz(value, 'Europe/London')
  return (utcDate.tz(tiz)).format('MM/DD/YYYY')
}

/**
 * Round currency calculations
 * @param val - Value to round
 */
function roundCurrency (value) {
  return Math.round(Number(value) * 100) / 100
}

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    store                : {},
    account              : {},
    products             : [],
    cart                 : [],
    dedicationUser       : {},
    dedicationAdRedirect : false,
    discount             : null,
    stripeConnectAccount : null
  },
  mutations: {
    setStore (state, store) { state.store = store },
    setAccount (state, account) { state.account = account },
    setStripConnectAccount (state, stripeConnectAccount) { state.stripeConnectAccount = stripeConnectAccount },
    setProducts (state, products) { // Sets price and deadlines
      let adjProducts = []
      for (let i = 0; i < products.length; i++) {
        let product = products[i]
        let info = getProductPriceAndDeadline(product, state.account.timezone)
        product['price'] = info.price
        product['deadline'] = filterDate(info.deadline, state.account.timezone)
        product['lastEndDate'] = filterDate(info.lastEndDate, state.account.timezone)
        adjProducts.push(product)
      }
      state.products = adjProducts
    },
    setCart (state, cart) { state.cart = cart },
    setDedicationUser (state, user) { state.dedicationUser = user },
    setDedicationAdRedirect (state, redirect) { state.dedicationAdRedirect = redirect },
    setDiscount (state, discount) { state.discount = discount },
    addItemToCart (state, item) {  state.cart.push(item) },
    removeItemFromCart (state, idx) { state.cart.splice(idx, 1) },
    increaseCartItemQuantity (state, idx) { state.cart[idx].quantity += 1 },
    decreaseCartItemQuantity (state, idx) { state.cart[idx].quantity -= state.cart[idx].quantity === 1 ? 0 : 1 },
    resetCartState (state) {
      state.cart     = []
      state.discount = null
    },
    resetStoreState (state) {
      state.store                = {}
      state.account              = {}
      state.products             = []
      state.cart                 = []
      state.dedicationUser       = {}
      state.dedicationAdRedirect = false
      state.discount             = null
      state.stripeConnectAccount = null
    }
  },
  actions: {
    setStore (context, store) { context.commit('setStore', store) },
    setAccount (context, account) { context.commit('setAccount', account) },
    setStripConnectAccount (context, stripeConnectAccount) { context.commit('setStripConnectAccount', stripeConnectAccount) },
    setProducts (context, products) { context.commit('setProducts', products) },
    setCart (context, cart) { context.commit('setCart', cart) },
    setDedicationUser (context, user) { context.commit('setDedicationUser', user) },
    addItemToCart (context, item) { context.commit('addItemToCart', item) },
    removeItemFromCart (context, idx) { context.commit('removeItemFromCart', idx) },
    decreaseCartItemQuantity (context, idx) { context.commit('decreaseCartItemQuantity', idx) },
    increaseCartItemQuantity (context, idx) { context.commit('increaseCartItemQuantity', idx) },
    resetCartState (context) { context.commit('resetCartState') },
    resetStoreState (context) { context.commit('resetStoreState') },
    setDedicationAdRedirect (context, redirect) { context.commit('setDedicationAdRedirect', redirect) },
    setDiscount (context, discount) { context.commit('setDiscount', discount) }
  },
  getters: {
    store (state) { return state.store },
    products (state) { return state.products },
    account (state) { return state.account },
    cart (state) { return state.cart },
    dedicationUser (state) { return state.dedicationUser },
    dedicationAdRedirect (state) { return state.dedicationAdRedirect },
    discount (state) { return state.discount },
    stripeConnectAccount (state) { return state.stripeConnectAccount },
    /**
     * Raw total does not include fees, discount, or shipping
     */
    rawCartTotal (state, getters) {
      let cartTotal = 0
      let items = getters.cart
      for (let i = 0; i < items.length; i++) {
        cartTotal += items[i].quantity * Number(items[i].itemPrice)
      }
      return roundCurrency(cartTotal)
    },
    /**
     * Cart total w/ shipping; NOTE!!! Discount applied here
     */
     cartTotalWithShipping (state, getters) {
       let raw = getters.rawCartTotal
       let shipping = getters.shipping.cost
       let rs = raw + shipping

       // Apply any discount at this level
       if (getters.discount && getters.discount.id) {
         if (getters.discount.typeDiscount === 1) rs = rs * (1 - (Number(getters.discount.amount) / 100.0))
         else rs = rs - Number(getters.discount.amount)
         if (rs < 0) rs = 0
       }

       return roundCurrency(rs)
     },
   /**
    * Cart total w/o shipping; NOTE!!! Discount applied here
    */
    cartTotalWithoutShipping (state, getters) {
      let total = getters.rawCartTotal

      // Apply any discount at this level
      if (getters.discount && getters.discount.id) {
        if (getters.discount.typeDiscount === 1) total = total * (1 - (Number(getters.discount.amount) / 100.0))
        else total = total - Number(getters.discount.amount)
        if (total < 0) total = 0
      }

      return roundCurrency(total)
    },
    /**
     * Cart total includes fees, discount, and shipping
     */
    cartTotal (state, getters) {
      let rawWithShipping = getters.cartTotalWithShipping
      let fee = (getters.store.bParentsPayConvenienceFee && rawWithShipping > 0) ? getters.totalFee : 0
      return roundCurrency(rawWithShipping + fee + getters.salesTaxAmount)
    },
    /**
     * Gets a list of active current year products
     * SIDEAFFECTS: Sets product price and deadline attributes
     */
    currentYearProducts (state, getters) {
      let ap = []
      for (let i = 0; i < state.products.length; i++) {
        let product = state.products[i]
        // If expired, exclude
        if (product.price === -1) continue
        // If ad product and builder is on, it will be behind a login, exclude
        if (product.typeProduct === 2 && getters.store.bDedicationAdTool) continue
        // Previous year ship-to-home, exclude
        if (product.typeShipping === 2 && product.year !== 2025) continue
        // If not of the current year, exclude
        if (![0, 2024, 2025].includes(product.year)) continue
        ap.push(product)
      }
      // Group product types together
      return ap.sort(function (a, b) {
        if (a.typeProduct < b.typeProduct) return -1
        if (a.typeProduct > b.typeProduct) return 1
        return 0
      })
    },

    /**
     * Finds when the store opens by checking the start dates of all products.
     * It looks through each product and their prices to find the soonest start date in the future.
     * If all start dates are in the past, returns null.
     */
    dateStoreOpens (state) {
      const timezone = state.account.timezone;
      const timezoneName = getTimezoneNameFromAbbrv(timezone) || '';
      const today = moment().tz(timezoneName);

      const allFutureDates = [];

      for (let i = 0; i < state.products.length; i++) {
        const product = state.products[i]
        // If ship to home or the year is not 2025, exclude
        if (product.typeShipping === 2 || product.year !== 2025) continue

        for (let j = 0; j < product.prices.length; j++) {
          const price = product.prices[j]
          const date = moment.tz(price.dateStart, timezoneName)

          // we only want to consider future dates
          if (date.isValid() && date.isAfter(today, 'day')) {
            allFutureDates.push(date)
          }
        }
      }

      return allFutureDates.length > 0 ? moment.min(allFutureDates) : null;
    },

    /**
     * Finds when the store more recently closed by checking the end dates of all products.
     * If all end dates are in the future, returns null.
     */
    dateStoreClosed (state) {
      const timezone = state.account.timezone;
      const timezoneName = getTimezoneNameFromAbbrv(timezone) || ''
      const today = moment().tz(timezoneName)

      const allPastDates = []

      for (let i = 0; i < state.products.length; i++) {
        const product = state.products[i]
        if (product.typeShipping === 2 || product.year !== 2025) continue

        for (let j = 0; j < product.prices.length; j++) {
          const price = product.prices[j]
          const date = moment.tz(price.dateEnd, timezoneName);

          // we only want to consider past dates
          if (date.isValid() && date.isBefore(today, 'day')) {
            allPastDates.push(date)
          }
        }
      }

      return allPastDates.length > 0 ? moment.max(allPastDates) : null;
    },
    /**
     * Gets a list of active current year products
     */
    previousYearbookProducts (state) {
      let pyp = []
      for (let i = 0; i < state.products.length; i++) {
        let product = state.products[i]
        // If expired, exclude
        if (product.price === -1) continue
        // If 2025 (current year), or it doesn't ship-to-home, exclude
        if (product.year > 2024 || product.typeShipping !== 2) continue
        pyp.push(product)
      }
      // Sort previous year products by ascending year
      return pyp.sort(function (a, b) {
        if (a.year < b.year) return -1
        if (a.year > b.year) return 1
        return 0
      })
    },
    /**
     * Gets dedication ad products
     */
    dedicationAdProducts (state) {
      let dap = []
      for (let i = 0; i < state.products.length; i++) {
        let product = state.products[i]
        if (product.price === -1) continue
        if (product.typeProduct !== 2) continue
        dap.push(product)
      }
      // Sort by ad name
      return dap.sort(function (a, b) {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })
    },
    /**
     * Gets donateable products
     */
     donateableProducts (state, getters) {
       // Break out if mandatory ship to home (typeShipToHome === 2)
       if (getters.store.typeShipToHome === 2) return []
       let allProducts = getters.currentYearProducts
       let donateableProducts = []
       for (let i = 0; i < allProducts.length; i++) {
         let product = allProducts[i]
         if (product.bCanDonate) {
           donateableProducts.push(product)
         }
       }
       return donateableProducts
     },
    /**
     * Gets shipping cost and calculation
     */
    shipping (state, getters) {
      let quantity    = 0
      let totalCost   = 0
      let calculation = ''
      // New for variable weight pricing
      let maxSize    = 8
      let maxBinding = 1
      let maxPages   = 0
      // Variable base and additional cost
      let baseCost       = 0
      let additionalCost = 0

      if (!getters.shipsToHome) return { cost: totalCost, calculation: calculation }

      // First loop for max values of yearbook projects
      for (let i = 0; i < state.cart.length; i++) {
        let item = state.cart[i]
        // Shippable yearbooks w/ project
        if (item.product.typeProduct === 1 && [3, 2].includes(item.product.typeShipping) && item.project) {
          maxSize    = Math.max(maxSize, (item.project.typeSize || 0))
          maxBinding = Math.max(maxBinding, (item.project.typeBook || 1))
          maxPages   = Math.max(maxPages, (item.project.countPages || 0))
        }
      }

      // Get additional and base sizes
      if ([5, 9].includes(maxBinding)) { // hard Cover
        if (maxSize === 8) {
          if (maxPages <= 100) baseCost = 8.75, additionalCost = 4.25
          else if (maxPages > 100 && maxPages <= 172) baseCost = 9.50,  additionalCost = 4.50
          else if (maxPages > 172 && maxPages <= 260) baseCost = 10.25,  additionalCost = 4.75
          else if (maxPages > 260 && maxPages <= 336) baseCost = 11.00, additionalCost = 5.25
          else if (maxPages > 336) baseCost = 11.75, additionalCost = 6.00
        } else { // 9x12 and landscape
          if (maxPages <= 100) baseCost = 10.25, additionalCost = 5.00
          else if (maxPages > 100 && maxPages <= 172) baseCost = 10.75, additionalCost = 5.25
          else if (maxPages > 172 && maxPages <= 260) baseCost = 11.75, additionalCost = 6.00
          else if (maxPages > 260 && maxPages <= 336) baseCost = 12.50, additionalCost = 6.50
          else if (maxPages > 336) baseCost = 13.25, additionalCost = 6.75
        }
      } else { // Soft cover
        if (maxPages <= 100) baseCost = 8.00, additionalCost = 4.00
        else if (maxPages > 100 && maxPages <= 172) baseCost = 8.75, additionalCost = 4.25
        else if (maxPages > 172 && maxPages <= 260) baseCost = 9.50, additionalCost = 4.50
        else if (maxPages > 260 && maxPages <= 336) baseCost = 10.25, additionalCost = 4.75
        else if (maxPages > 336) baseCost = 11.00, additionalCost = 5.25
      }

      // Now loop to calculate total and calulcation
      for (let i = 0; i < state.cart.length; i++) {
        let item = state.cart[i]
        if (item.product.typeProduct === 1 && [3, 2].includes(item.product.typeShipping)) { // shippable yearbook product
          if (quantity === 0) totalCost += (baseCost + (additionalCost * (item.quantity - 1)))
          else totalCost += (additionalCost * item.quantity)
          quantity += item.quantity
        }
      }

      // Show how we calculated shipping if > 1 book
      if (quantity > 1) calculation = `$${baseCost.toFixed(2)} + ($${additionalCost.toFixed(2)} x ${quantity - 1})`

      return { cost: totalCost, calculation: calculation }
    },
    /**
     * Is this a bulk ship to home book
     */
    bulkShipToHome (state) {
      for (let i = 0; i < state.cart.length; i++) {
        if (state.cart[i].product.typeShipping === 3) return true
      }
      return false
    },
    /**
     * Is this a summer ship to home book
     */
    summerShipToHome (state) {
      for (let i = 0; i < state.cart.length; i++) {
        if (state.cart[i].product.typeShipping === 2) return true
      }
      return false
    },
    /**
     * Ship to home checks for both summer and bulk ship to home
     */
    shipsToHome (state, getters) {
      return getters.bulkShipToHome || getters.summerShipToHome
    },
    /**
     * Calculates dedication ad fee
     */
    dedicationAdFee (state, getters) {
      if (getters.cartTotalWithShipping === 0) return 0
      let adTotal = 0
      let fixedFeeTotal = 0
      for (let i = 0; i < state.cart.length; i++) {
        let item = state.cart[i]
        // Dedication ad products
        if (item.product.typeProduct === 2) {
          // If the product year is before 2025 or SimplePix, the fee is applied against the total of all ads
          if (item.product.year < 2025 || getters.account.parentId === '87PNV2') {
            if (state.store.bDedicationAdTool) {
              adTotal += Number(item.itemPrice * item.quantity)
            }
          // Otherwise, 2025 ads have a fixed fee or variable if DTS/Commission
          } else {
            if (getters.directToSchoolOrCommission) {
              adTotal += Number(item.itemPrice * item.quantity)
            } else {
              fixedFeeTotal += (5.00 * item.quantity)
            }
          }
        // Text dedication ad products
        } else if (item.product.typeProduct === 3 && item.product.year >= 2025) {
          fixedFeeTotal += getters.directToSchoolOrCommission ? (9.99 * item.quantity) : (1.00 * item.quantity)
        }
      }
      if (getters.directToSchoolOrCommission) {
        // If DTS or commission, rate is 20% of all dedication ads with a min of $10.00
        // plus the fixed fee for text dedication ads
        let adTotalFee = 0
        // If adTotal is 0, we don't want to have that minimum $10 fee
        if (adTotal > 0) {
          adTotalFee = Math.max(adTotal * 0.20, 10.00)
        }
        return roundCurrency(adTotalFee + fixedFeeTotal)
      }
      // Otherwise, rate is 5% of all dedication ads plus any fixed fees
      return roundCurrency(adTotal * 0.05 + fixedFeeTotal)
    },
    /**
     * Convenience fee is max of 5% or 50 cents
     */
    convenienceFee (state, getters) {
      if (getters.cartTotalWithShipping === 0) return 0
      // Standard fee is 6%
      let feePercentage = 0.06
      let feeFixed = 0.00
      // If it is a store only account (typeSoftware === 9), it trumps all rates at 7.5%
      if (getters.account.typeSoftware === 9) {
        feePercentage = 0.075
      // Else if Stripe Connect, load rate from account record
      } else if (getters.stripeConnectAccount && getters.stripeConnectAccount.id) {
        feePercentage = parseFloat(getters.stripeConnectAccount.feePercentage)
        feeFixed = parseFloat(getters.stripeConnectAccount.feeFixed)
      // Else if DTS or commission, rate is 7.5%
      } else if (getters.directToSchoolOrCommission) {
        feePercentage = 0.075
      }

      let feeDollar = roundCurrency(getters.cartTotalWithShipping * feePercentage + feeFixed)

      return Math.max(roundCurrency(feeDollar), 0.50)
    },
    /**
     * Total fee includes convenience fee and dedication ad fee
     */
    totalFee (state, getters) {
      if (getters.cartTotalWithShipping === 0) return 0
      return roundCurrency(getters.convenienceFee + getters.dedicationAdFee)
    },
    /**
     * Ad building is using dedication ad tool and ad products available
     */
    offersAdBuilding (state, getters) {
      if (!state.store.bDedicationAdTool) return false
      return getters.dedicationAdProducts.length > 0
    },
    /**
     * Ad building is using dedication ad tool and ad products available
     */
    offersKidsPages (state, getters) {
      let kp = false
      for (let i = 0; i < getters.dedicationAdProducts.length; i++) {
        if (getters.dedicationAdProducts[i].bKidsPage) {
          kp = true
          break
        }
      }
      return kp
    },
    salesTaxAmount (state, getters) {
      let salesTaxRate = Number(getters.store.salesTaxRate)
      if (getters.store.bCollectSalesTax && salesTaxRate > 0 && !getters.summerShipToHome) {
        let totalTaxable = getters.store.bCollectSalesTaxOnShipping ? getters.cartTotalWithShipping : getters.cartTotalWithoutShipping
        let salesTaxAmount = roundCurrency(totalTaxable * salesTaxRate)
        return salesTaxAmount
      }
      return 0
    },
    directToSchoolOrCommission (state, getters) {
      return getters.account.bDirectToSchool || getters.account.parent.bCommission || getters.account.bCommission
    },
    maxProductYear (state) {
      let maxYear = 0
      for (let i = 0; i < state.cart.length; i++) {
        let item = state.cart[i]
        if (item.product && item.product.year > maxYear) {
          maxYear = item.product.year
        }
      }
      return maxYear
    }
  }
})
