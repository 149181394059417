<template>
  <header class="header_wrap fixed-top header_with_topbar">
    <div class="bottom_header dark_skin main_menu_uppercase">
      <div class="container">
        <nav class="navbar navbar-expand-lg">
          <logo :isLanding="$route.name === 'Stores'"></logo>
          <button v-if="showLinks" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarHeaderLinks" aria-expanded="false">
            <span class="ion-android-menu"></span>
          </button>
          <div v-if="showLinks" class="collapse navbar-collapse justify-content-center" id="navbarHeaderLinks">
            <header-links :bAds="bAds"></header-links>
          </div>
          <ul class="navbar-nav attr-nav align-items-center">
            <cart-dropdown v-if="showLinks"></cart-dropdown>
            <li class="dropdown cart_dropdown" v-else style="height: 60px !important"></li>
          </ul>
          <ul class="navbar-nav attr-nav align-items-center">
            <donate-dropdown v-if="showLinks && donateableProducts.length > 0"></donate-dropdown>
            <li class="dropdown cart_dropdown" v-else style="height: 60px !important"></li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</template>

<style>
.header_wrap {
  background-color: var(--primary-color);
  position: fixed !important;
}
</style>

<script>
import { mapGetters } from 'vuex'
import HeaderLinks from '@/components/layout/header/HeaderLinks'
import CartDropdown from '@/components/layout/header/Cart/CartDropdown'
import DonateDropdown from '@/components/layout/header/DonateDropdown'
import Logo from '@/components/layout/header/Logo'

export default {
  name: 'HeaderMain',
  props: {
    bAds: {
      type: Boolean,
      default: false
    },
    showLinks: {
      type: Boolean,
      default: true
    }
  },
  components: {
    HeaderLinks,
    CartDropdown,
    Logo,
    DonateDropdown
  },
  computed: {
    ...mapGetters([
      'donateableProducts'
    ])
  }
}
</script>
