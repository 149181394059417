<template>
  <li class="dropdown cart_dropdown">
    <a class="nav-link cart_trigger" href="#" data-toggle="dropdown">
      <i class="linearicons-gift"></i>
      <span class="cart_count">DONATE</span>
    </a>
    <div class="cart_box donate_box dropdown-menu dropdown-menu-right">
      <ul class="cart_list">
        <p style="color: black;
          margin-left: 15px;
          margin-top: 10px;
          margin-bottom: -5px;">
          <strong>SELECT A PRODUCT TO DONATE</strong>
        </p>
        <li class='dropdown-li'>
          <div v-for="(product, idx) in donateableProducts" v-bind:key="product.id">
            <a :href="'/' + store.link + '/products/' + product.id + '?donate=true'" >
              <img :src="productImageUri(product)" alt="cart_thumb1" />
              <span style="color: black !important;">{{ product.name }}</span>
              <span class="cart_quantity">
                {{ productDescription(product) }}
              </span>
              <p style="clear: both">${{product.price.toFixed(2) }}</p>
            </a>
            <hr style="margin-top: -15px;" v-if="donateableProducts.length > 1 && idx !== donateableProducts.length - 1">
          </div>
        </li>
      </ul>
    </div>
  </li>
</template>

<style>
.cart_count {
  background-color: #000;
}
.view-cart {
  color: #fff !important;
}
.donate_box {
  width: 300px;
}
</style>

<script>
import { mapGetters } from 'vuex'
import { storeCommonMixin } from '@/mixins/storeCommon'

export default {
  name: 'DonateDropdown',
  mixins: [storeCommonMixin],
  computed: {
    ...mapGetters([
      'store',
      'donateableProducts'
    ])
  },
  methods: {
    productDescription (product) {
      let description = product.description
      if (description.length > 125) {
        description = description.substring(0, 122) + '...'
      }
      return description
    }
  }
}
</script>
