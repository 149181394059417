<template>
  <div class="max-vertical-height" :style="{
    'background-image': $route.path === '/amilestonegroup' || $route.path === '/wilcoxrecognition' ? 'url(\'/assets/images/high-school-kids-walking.png\')' : 'url(\'/assets/images/StoreBg_1920x1000.jpg\')',
    'background-repeat': 'none',
    'background-size': 'cover' }" :class="['/amilestonegroup', '/wilcoxrecognition'].includes($route.path) ? 'amg-background-image' : ''">
    <div class="main_content" >
      <div class="section page-section simple-suggest-position">
        <div class="container" >
          <div class="row">
            <div class="col-12">
              <vue-simple-suggest
                pattern="\w+"
                v-model="storeSearch"
                :list="getStores"
                :max-suggestions="15"
                :min-length="0"
                :debounce="400"
                :filter-by-query="false"
                :prevent-submit="true"
                :controls="{
                  selectionUp: [38, 33],
                  selectionDown: [40, 34],
                  select: [13, 36],
                  hideList: [27, 35]
                }"
                :mode="mode"
                :nullable-select="true"
                ref="suggestComponent"
                placeholder="Start typing your school name here"
                value-attribute="id"
                display-attribute="name"
                @select="onSuggestSelect">
                <div class="g">
                  <input type="text" placeholder='Start typing your school name here' style="text-align: center">
                </div>
                <template slot="misc-item-above" slot-scope="{ suggestions }">
                  <template v-if="suggestions.length > 0">
                    <div class="misc-item">
                      <span>{{ suggestions.length }} suggestion(s) are shown...</span>
                    </div>
                    <hr style="margin-top: 2px !important; margin-bottom: 2px !important;">
                  </template>
                  <div class="misc-item" v-else-if="!loading">
                    <span>No results</span>
                  </div>
                </template>
                <div slot="suggestion-item" slot-scope="scope" :title="scope.suggestion.description">
                  <div class="text">
                    <span v-html="boldenSuggestion(scope)"></span>
                  </div>
                </div>
                <div class="misc-item" slot="misc-item-below" v-if="loading">
                  <span>Loading...</span>
                </div>
              </vue-simple-suggest>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.simple-suggest-position {
  position: absolute;
  top: 33%;
  width: 100%
}
@media screen and (max-width: 1200px) {
  .amg-background-image {
    background-position-x: -175px;
  }
}
@media screen and (max-width: 1000px) {
  .amg-background-image {
    background-position-x: -275px;
  }
}
@media screen and (max-width: 800px) {
  .amg-background-image {
    background-position-x: -375px;
  }
}
@media screen and (max-width: 600px) {
  .amg-background-image {
    background-position-x: -375px;
  }
}
@media screen and (max-width: 400px) {
  .amg-background-image {
    background-position-x: -375px;
  }
}
@media screen and (max-width: 200px) {
  .amg-background-image {
    background-position-x: -400px;
  }
}
</style>

<script>
import 'vue-simple-suggest/dist/styles.css'
import { mapGetters }            from 'vuex'
import to                        from '@/utils/to'
import axios                     from 'axios'
import VueSimpleSuggest          from 'vue-simple-suggest'
import Repository                from '@/repositories/RepositoryFactory'
import { storeCommonMixin }      from '@/mixins/storeCommon'
const StoreRepository            = Repository.get('stores')
const AccountRepository          = Repository.get('accounts')
const ProductRepository          = Repository.get('products')
const DedicationAdUserRepository = Repository.get('dedication_ad_users')
const DedicationAdRepository     = Repository.get('dedication_ads')
let $ = window.$

const PIXAMI_BASE_URI  = 'https://bookbuilder.pixami.com/AdCreation'
const GET_ALBUM_STATUS = 'home3/Album.psp?Cmd=GetAlbumStatus&ID='
const CORS_BYPASS_URI  = 'https://studiosource-cors-proxy.herokuapp.com'
const AD_PRODUCT_CODES = { '51303': 1, '51302': 2, '51301': 3, '51300': 4 }

export default {
  name: 'Stores',
  mixins: [storeCommonMixin],
  components: { VueSimpleSuggest },
  data () {
    return {
      mode        : 'input',
      storeSearch : null,
      loading     : false
    }
  },
  computed: {
    ...mapGetters([
      'store',
      'account'
    ])
  },
  methods: {
    /**
     * Gets list of stores by search
     * @param inputValue - What the user searched for
     */
    async getStores (inputValue) {
      this.loading = true
      if (!inputValue) this.storeSearch = ''
      let qualifiers = { q: inputValue, status: 1, limit: 15, include: ['account'], order: 'name ASC' }
      if (this.$route.path === '/amilestonegroup' || this.$route.path === '/wilcoxrecognition') {
        let err, amgAccounts
        let amgIds = []
        if (this.$route.path === '/wilcoxrecognition') {
          [err, amgAccounts] = await to(AccountRepository.get({ parentId: 'BYZRIL' }))
          if (!amgAccounts) throw new Error(err)
          amgIds = amgAccounts.data.map(function (a) { return a.id })
        } else {
          [err, amgAccounts] = await to(StoreRepository.getAmgStoreAccounts())
          if (!amgAccounts) throw new Error(err)
          amgIds = amgAccounts.data.map(function (a) { return a.id })
        }
        qualifiers['accountId'] = amgIds.length === 0 ? 'XXXXXX' : amgIds
      }
      return new Promise((resolve, reject) => {
        StoreRepository.get(qualifiers).then((response) => {
          let stores = response.data
          let results = []
          for (let i = 0; i < stores.length; i++) {
            let s = stores[i]
            results.push({
              id   : s.id,
              link : s.link,
              name : `${s.account.name} / ${s.account.city}, ${s.account.state}`
            })
          }
          resolve(results)
          this.loading = false
        }).catch((err) => {
          reject(err)
          this.loading = false
        })
      })
    },
    /**
     * On selected
     * @param suggest - Suggested store
     */
    onSuggestSelect (suggest) {
      window.location.href = `/${suggest.link}`
    },
    /**
     * Bolds suggestions
     * @param scope
     */
    boldenSuggestion(scope) {
      try {
        if (!scope) return scope
        const { suggestion, query } = scope
        let result = this.$refs.suggestComponent.displayProperty(suggestion)
        if (!query) return result;
        const texts = query.split(/[\s-_/\\|.]/gm).filter(t => !!t) || [''];
        return result.replace(new RegExp('(.*?)(' + texts.join('|') + ')(.*?)','gi'), '$1<b>$2</b>$3');
      } catch (e) {
        console.log(e)
      }
    },
    /**
     * Handles ad redirect from Pixami
     * @param albumId: Dedication ad product album ID
     * @param email: Dedication ad user email
     */
    handleAdRedirect: async function (albumId, email) {
      let err, user;
      [err, user] = await to(DedicationAdUserRepository.get({ email: email.toLowerCase() }))
      if (!user || user.data.length === 0) {
        if (this.store && this.store.id) {
          this.$store.dispatch('setDedicationAdRedirect', false)
          $('#main-preloader').delay(500).fadeOut(500).addClass('loaded')
          window.location.href = `/${this.store.link}/ads`
        } else {
          $('#main-preloader').delay(500).fadeOut(500).addClass('loaded')
        }
        throw new Error(err)
      }

      let dUser = user.data[0]
      this.$store.dispatch('setDedicationUser', dUser)

      let response;
      [err, response]      = await to(axios.get(CORS_BYPASS_URI, { headers: { 'Target-URL': `${PIXAMI_BASE_URI}/${GET_ALBUM_STATUS}${albumId}` } }))
      let albumResponseXml = $($.parseXML(response.data))
      let adUriPreview     = albumResponseXml.find('FirstPageRenderURL').text()
      let adTitle          = albumResponseXml.find('AlbumTitle').text()
      let productCode      = albumResponseXml.find('ProductID').text()
      let typeDedicationAd = AD_PRODUCT_CODES[productCode]

      let products;
      [err, products] = await to(ProductRepository.get({
        accountId        : this.store.accountId,
        typeProduct      : 2,
        typeDedicationAd : typeDedicationAd,
        include          : ['prices'],
        year             : 2025 }))
      if (!products || products.data.length === 0) throw new Error(err)

      let ads;
      [err, ads] = await to(DedicationAdRepository.get({ albumId: albumId }))
      if (!ads) throw new Error(err)

      let product      = products.data[0]
      let dedicationAd = null;
      if (ads.data.length === 0) {
        // Create ad
        let adData = {
          dedicationAdUserId : dUser.id,
          productId          : product.id,
          storeId            : this.store.id,
          productName        : product.name,
          productDescription : product.description,
          typeDedicationAd   : typeDedicationAd,
          productPrice       : this.getProductPrice(product, this.account.timezone),
          albumId            : albumId,
          uriPreview         : adUriPreview,
          title              : adTitle,
          productCode        : productCode
        }

        let dad;
        [err, dad] = await to(DedicationAdRepository.create(adData))
        if (!dad) throw new Error(err)
        dedicationAd = dad.data[0]
      } else {
        dedicationAd = ads.data[0]
      }
      if (this.store && this.store.id && dedicationAd.id) {
        this.$store.dispatch('setDedicationAdRedirect', false)
        $('#main-preloader').delay(500).fadeOut(500).addClass('loaded')
        window.location.href = `/${this.store.link}/ads/${dedicationAd.id}`
      } else {
        $('#main-preloader').delay(500).fadeOut(500).addClass('loaded')
      }
    }
  },
  created () {
    if (this.$route.query.AlbumID && this.$route.query.AlbumID !== '***Album***' && this.$route.query.User) { // Redirect from Pixami ad creator
      this.$store.dispatch('setDedicationAdRedirect', true)
      this.handleAdRedirect(this.$route.query.AlbumID, this.$route.query.User)
    } else if (this.$route.query.AlbumID === '***Album***' && this.store && this.store.id) {
      window.location.href = `/${this.store.link}`
    } else if (this.$route.query.backToStore && this.store && this.store.id) {
      window.location.href = `/${this.store.link}`
    } else {
      this.$store.dispatch('resetStoreState')
      setTimeout(function () {
        $('#main-preloader').delay(500).fadeOut(500).addClass('loaded')
      }, 500)
    }
  },
  mounted () {
    if (this.$route.path === '/amilestonegroup') $('.header_wrap').css('background-color', '#2d464d')
    else if (this.$route.path === '/wilcoxrecognition') $('.header_wrap').css('background-color', '#053385')
    else $('.header_wrap').css('background-color', '#F58220')
  }
}
</script>
