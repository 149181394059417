<template>
  <li class="dropdown cart_dropdown">
    <a class="nav-link cart_trigger" href="#" data-toggle="dropdown">
      <i class="linearicons-cart"></i>
      <span class="cart_count">{{ cart.length }}</span>
    </a>
    <div class="cart_box dropdown-menu dropdown-menu-right">
      <ul class="cart_list">
        <cart-dropdown-item v-for="(item, idx) in cart" :key="idx" :item="item" :idx="idx"></cart-dropdown-item>
        <li v-if="cart.length === 0"><span>Your cart is empty.</span></li>
      </ul>
      <div class="cart_footer">
        <p class="cart_total">
          <strong>Subtotal:</strong>
          <span class="cart_price">
            <span class="price_symbole">$</span></span>{{ rawCartTotal.toFixed(2) }}
          </p>
        <p class="cart_buttons">
          <router-link class="btn btn-fill-line btn-radius view-cart" :to="{ name: 'Cart' }">View Cart</router-link>
          <router-link class="btn btn-fill-out btn-radius view-cart" :to="{ name: 'Checkout' }">Checkout</router-link>
        </p>
      </div>
    </div>
  </li>
</template>

<style>
.cart_count {
  background-color: #000;
}
.view-cart {
  color: #fff !important;
}
</style>

<script>
import { mapGetters } from 'vuex'
import CartDropdownItem from '@/components/layout/header/Cart/CartDropdownItem'

export default {
  name: 'CartDropdown',
  components: { CartDropdownItem },
  computed: {
    ...mapGetters([
      'cart',
      'rawCartTotal'
    ])
  }
}
</script>
