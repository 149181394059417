import StoreRepository from './StoreRepository'
import ProductRepository from './ProductRepository'
import AccountRepository from './AccountRepository'
import OnlineOrderRepository from './OnlineOrderRepository'
import OnlineOrderItemRepository from './OnlineOrderItemRepository'
import DedicationAdUserRepository from './DedicationAdUserRepository'
import DedicationAdRepository from './DedicationAdRepository'
import CustomerIoActionRepository from './CustomerIoActionRepository'
import DiscountRepository from './DiscountRepository'
import TeacherGradeCombinationRepository from './TeacherGradeCombinationRepository'
import ProjectRepository from './ProjectRepository'

const repositories = {
  'stores'              : StoreRepository,
  'products'            : ProductRepository,
  'accounts'            : AccountRepository,
  'online_orders'       : OnlineOrderRepository,
  'online_order_items'  : OnlineOrderItemRepository,
  'dedication_ads'      : DedicationAdRepository,
  'dedication_ad_users' : DedicationAdUserRepository,
  'customer_io_actions' : CustomerIoActionRepository,
  'discounts'           : DiscountRepository,
  'projects'            : ProjectRepository,
  'teacher_grade_combinations' : TeacherGradeCombinationRepository
}

export default {
  get: name => repositories[name]
}
