<template>
  <div class="max-vertical-height">
    <breadcrumbs title="Did I Already Order?" :crumbs="[
      { name: store.name, route: 'Store', params: { link: store.link } },
      { name: 'Did I Already Order?', route: 'OrderLookup', params: { link: store.link } }]">
    </breadcrumbs>
    <div class="main_content">
      <div class="container">
        <form class="section" style="padding-top: 35px; padding-bottom: 25px;">
          <h4>Order Lookup</h4>
          <p>Enter your email and school year to search for matching purchases.</p>
          <div class="form-group">
            <label for="orderCheckEmail">Email</label>
            <input placeholder="example@gmail.com" class="form-control" name="orderCheckEmail" type="email"
              id="orderCheckEmail" v-model="checkOrderEmail">
          </div>
          <div class="form-group">
            <select class="form-control" required name="schoolYear" v-model="schoolYear">
              <option value="">Select a school year</option>
              <option value="2025">2024-2025</option>
              <option value="2024">2023-2024</option>
            </select>
          </div>
          <div ref="recaptcha" class="g-recaptcha" :data-sitekey="siteKey"></div>
          <button v-if="!checkingForOrder"
            type="submit"
            class="btn btn-fill-out mt-3"
            name="check"
            :disabled="disabledCheckButton"
            @click.prevent="checkForOrder">
            Search
          </button>
          <button v-else class="btn btn-fill-out mt-3" type="button" disabled>
            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          </button>
        </form>
        <div
          :class="'alert alert-'+(foundOnlineOrder ? 'success' : 'danger' )"
          role="alert"
          v-if="!checkingForOrder && alertMessage.length > 0">
          {{ alertMessage }}
        </div>
        <!-- Order info -->
        <div class="section" v-if="!checkingForOrder" style="padding-top: 10px;">
          <div v-for="(order, orderIndex) in matchingOrders" :key="orderIndex" class="card mb-3">
            <div class="card-header">
              <strong>Order no. 1{{ order.id }}</strong>
              <strong style="float: right">{{ new Date(order.orderedOn) | dateFormat('MM/DD/YYYY') }}</strong>
            </div>
            <div class="card-body">
              <div v-for="(orderItem, orderItemIndex) in order.items" :key="orderItemIndex" style="margin-bottom: 15px;">
                <h5 class="card-title" style="margin-bottom: 5px; text-decoration: underline">{{ orderItem.productName }}</h5>
                <table>
                  <tr class="order-item-attribute">
                    <td><strong>Quantity: </strong></td>
                    <td>&nbsp;&nbsp;{{ orderItem.quantity }}</td>
                  </tr>
                  <tr class="order-item-attribute">
                    <td><strong>Teacher: </strong></td>
                    <td>&nbsp;&nbsp;{{ orderItem.studentTeacher }}</td>
                  </tr>
                  <tr class="order-item-attribute">
                    <td ><strong>Grade: </strong></td>
                    <td>&nbsp;&nbsp;{{ orderItem.studentGrade }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- Loading spinner -->
        <div v-else class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.order-item-attribute {
  font-size: 14px;
  margin-bottom: 5px !important;
}
</style>

<script>
import to from '@/utils/to'
import { mapGetters } from 'vuex'
import { storeCommonMixin } from '@/mixins/storeCommon'
import Breadcrumbs from '@/components/layout/header/Breadcrumbs'
import Repository from '@/repositories/RepositoryFactory'
const OnlineOrderRepository = Repository.get('online_orders')
const OnlineOrderItemRepository = Repository.get('online_order_items')

export default {
  name: 'OrderLookup',
  mixins: [storeCommonMixin],
  components: { Breadcrumbs },
  data() {
    return {
      checkOrderEmail: '',
      schoolYear: '',
      checkingForOrder: false,
      foundOnlineOrder: false,
      matchingOrders: [],
      alertMessage: '',
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      sessionSearches: 0
    }
  },
  computed: {
    ...mapGetters([
      'account',
      'store',
    ]),
    disabledCheckButton() {
      // If null or no email or no school year, search is disabled
      return !this.checkOrderEmail || this.checkOrderEmail.length < 1 || !this.schoolYear || !this.validEmailAddress
    },
    validEmailAddress () {
      const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return this.checkOrderEmail && this.checkOrderEmail.match(validEmailRegex)
    }
  },
  methods: {
    /**
    * Checks for an order by billing email
    */
    checkForOrder: async function () {
      // Redirect if directly accessed and not through the store
      if (!this.store.id || !this.account.id) {
        window.location.href = '/'
        return
      }

      // Next, check for reCAPTCHA
      const recaptchaResponse = window.grecaptcha.getResponse()
      if (!recaptchaResponse) {
        alert('Please complete the reCAPTCHA.')
        return
      }

      // If no email provided, return
      if (!this.checkOrderEmail) return

      if (!this.validEmailAddress) {
        alert('Please enter a valid email address.')
        return
      }

      this.sessionSearches += 1
      if (this.sessionSearches > 5) {
        alert('To continue to search, please refresh the page and complete the reCAPTCHA again.')
        return
      }
      this.checkingForOrder = true
      this.foundOnlineOrder = false
      this.matchingOrders = []
      this.alertMessage = ''

      // Grab all potential order matches regardless of year
      const [err, orders] = await to(OnlineOrderRepository.get({
        accountId: this.account.id,
        billingEmail: this.checkOrderEmail.toLowerCase(),
        typePayment: 2, // CC payments only
        typeShipping: [0, 1, 2, 3],
        bDeclined: false,
        order: 'id DESC',
        fields: 'id, createdAt'
      }))
      if (err) {
        this.alertMessage = 'An error occurred while checking for an order. We have been notified.'
        this.checkingForOrder = false
        throw new Error(err)
      }

      // If no orders returned, indicate that none have been found
      if (!orders || orders.data.length === 0) {
        this.alertMessage = `No order was found for ${this.checkOrderEmail}`
        this.checkingForOrder = false
        this.foundOnlineOrder = false
        return
      }

      // Otherwise, get the corresponding order items for the potential orders
      // so we can then check against the school year selected
      const allOrderIds = orders.data.map(function (o) { return o.id })
      const [ooiErr, onlineOrderItems] = await to(OnlineOrderItemRepository.get({
        onlineOrderId: allOrderIds,
        year: this.schoolYear,
        fields: 'onlineOrderId,studentTeacher,studentGrade,productName,quantity,createdAt,year'
      }))
      if (ooiErr) {
        this.alertMessage = 'An error occurred while checking for an order. We have been notified'
        this.checkingForOrder = false
        throw new Error(ooiErr)
      }

      // If we have order items, it means we have matching orders
      // for the email and school year selected
      if (onlineOrderItems && onlineOrderItems.data.length > 0) {
        let matchingOrdersTemp = {}
        for (let i = 0; i < onlineOrderItems.data.length; i++) {
          const item = onlineOrderItems.data[i]
          if (matchingOrdersTemp[item.onlineOrderId]) {
            matchingOrdersTemp[item.onlineOrderId]['items'].push(item)
          } else {
            matchingOrdersTemp[item.onlineOrderId] = {
              'id': item.onlineOrderId,
              'orderedOn': item.createdAt,
              'items': [item]
            }
          }
        }
        this.matchingOrders = matchingOrdersTemp
        this.foundOnlineOrder = true
        this.alertMessage = `${Object.keys(matchingOrdersTemp).length > 1 ? 'Orders' : 'Order'} found for ${this.checkOrderEmail}`
      } else {
        this.alertMessage = `No order was found for ${this.checkOrderEmail}`
        this.foundOnlineOrder = false
      }

      this.checkingForOrder = false
    },
    /**
     * Re-renders recaptcha if necessary
     */
    renderRecaptcha () {
      if (typeof window.grecaptcha !== 'undefined') {
        window.grecaptcha.render(this.$refs.recaptcha, {
          sitekey: this.siteKey,
        })
      }
    },
  },
  mounted () {
    this.renderRecaptcha()
  }
}
</script>
